<template>
  <div class="level">
    <div class="level-item">
      <div class="has-text-centered has-background-warning error-box" v-show="message">
        <font-awesome-icon icon="exclamation-circle" class="is-size-5" v-bind:class="messageClass"/>
        <span class="has-text-weight-bold error-message">
              {{message}}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoodleMessage',
  props: ['message', 'messageClass']
}
</script>

<style scoped lang="scss">
  .error-message {
    margin-left: 1em;
    font-size: 0.8em;
  }

  .error-box {
    margin-top: 2em;
    padding: 1em;
  }
</style>
