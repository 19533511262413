export const SERVER_ERROR = 'Unable to process your request, please check your program and try again later, or contact JDoodle Support at\n' +
  'jdoodle@nutpan.com.'

export const ROBOT_CONST = {
  VALIDATE_ROBOT_CHECK: 'ValidateRobotCheck',
  ROBOT_ERROR: 'Your request seems invalid, please try again.',
  ROBOT_IDE_ERROR: 'Your request seems invalid. Please try to execute again.\nPlease note, third party cookies are required to run programs. Please check your browser cookie settings.\n\nIf the problem persists, please try the below solutions: \na) refresh the page \nb) clear the cache \nc) login\nd) try in incognito mode\ne) contact JDoodle support - hello@jdoodle.com'
}

export const IDE_CONST = {
  OUTPUT_EDITOR: 'output',
  CODE_EDITOR: 'code',
  FILE_EDITOR: 'myFilesEditor',
  HTML_BODY_EDITOR_NAME: 'html-body-code',
  JS_CODE_EDITOR_NAME: 'js-code',
  CSS_CODE_EDITOR_NAME: 'css-code',
  HTML_HEAD_EDITOR_NAME: 'html-head-code'
}
